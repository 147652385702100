<template>
  <div class="container" v-title :data-title="$t('i18n.groupManagement')">
    <div id="outer-title">{{ $t("i18n.groupManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "BaseviewAllot",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加小组"), // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "companyId",
          label: "companyName",
          align: "center",
          rows: false,
          type: "remote",
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val);
          },
          change: (id) => {
            getAllotLevel(id);
          },
        },
        {
          prop: "name",
          label: "groupName",
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            return `<a>${row.name}</a>`;
          },
          method: (row) => {
            console.log(row,"row");
            router.push({
              path: "/system/baseviewAllotUpdate",
              query: { id: row.id, name: row.name },
            });
          },
        },
        {
          prop: "level",
          label: "groupLevel",
          align: "center",
          type: "select",
          props: { label: "label", value: "value" },
          data: [],
          change: (level) => {
            getAllotParent(level);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "parentName",
          label: "parentGroup",
          align: "left",
          row: false,
          formatter: (row) => {
            return `<a>${row.parentName}</a>`;
          },
          method: (row) => {
            if(row.level!=0){
              router.push({
              path: "/system/baseviewAllotUpdate",
              query: { id: row.parentId, name: row.parentName },
            });
            }else{
              router.push({
              path: "/system/companyMaintaincou",
              query: { id: row.parentId, name: row.parentName },
            });
            }
          },
        },
        {
          prop: "elevatorNum",
          label: "numberOfElevator",
          align: "center",
          row: false,
        },
        {
          prop: "parentId",
          label: "parentGroup",
          align: "center",
          show: false,
          rows: false,
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
      companyId: "",
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.getAllAllot(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val, pages) => {
      // 新增
      await proxy.$api.system.addAllot(val);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const remoteCompanyMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "companyId";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.system.getCompanyByPerson(val).then((data) => {
            state.columns[index].data = data.data;
            state.columns[index].loading = false;
          });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    const getAllotLevel = async (id) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "level";
      });
      state.companyId = id;
      let { data } = await proxy.$api.system.getAllotLevel({ companyId: id });
      data.push(data.length + 1);
      let arr = [];
      data.map((item) => {
        let obj = { label: item, value: item };
        arr.push(obj);
      });
      state.columns[index].data = arr;
    };

    const getAllotParent = async (level) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "parentId";
      });
      let { data } = await proxy.$api.system.getAllotParent({
        companyId: state.companyId,
        level: level,
      });
      state.columns[index].data = data;
      state.columns[index].show = data.length > 0;
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      remoteCompanyMethod,
      getAllotLevel,
      getAllotParent,
    };
  },
};
</script>
